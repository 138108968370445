import * as React from "react";

export function useChangeNetwork(): () => Promise<any> {
  const changeNetwork = React.useCallback(() => {
    const { ethereum } = window as any
    if (ethereum) {
      return ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${(250).toString(16)}` }],
        })
        .catch((switchError: any) => {
          if (switchError.code === 4902) {
            ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: `0x${(250).toString(16)}`,
                    chainName: "Fantom Opera",
                    nativeCurrency: {
                      name: "Fantom",
                      symbol: "FTM",
                      decimals: 18,
                    },
                    rpcUrls: ['https://rpc.ftm.tools'],
                    blockExplorerUrls: [
                      "https://ftmscan.com/",
                    ],
                  },
                ],
              })
              .catch(console.error);
          }
        });
    }
  }, []);

  return changeNetwork;
}