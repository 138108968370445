import React from "react";
import styled from "styled-components";
import { NavLink } from 'react-router-dom'
import { useWeb3React } from "../../hooks";
import { useInjected } from "../../utils/injectedContext";
import { useChangeNetwork } from "../../hooks/useChangeNetwork";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useEagerConnect, useInactiveListener } from "../../hooks";
import Footer from "components/Footer";
import { UnsupportedChainIdError } from "@web3-react/core";

const Bar = styled.div`
  z-index: 1;
  height: 90px;
  width: 100%;
  background: white;
  padding: 10px 10%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 6px 0 #0000001e;
  position: fixed;

  @media (max-width: 430px) {
    max-height: 60px;
  }
`

const Links = styled.div`
  display: flex;
  align-self: center;
  font-weight: 500;
  font-size: 22px;
`

const StyledLink = styled(NavLink)`
  padding: 0 30px 0 10px;
  align-self: center;
  color: inherit;
  text-decoration: none;

  &.is-active {
    text-decoration: underline;
    text-decoration-color: #00000060;
  }
`

const ConnectButton = styled.button`
  padding: 2px 10px;
  border: none;
  border-radius: 8px;
  background-color: #4f8eff;
  color: white;
  box-shadow: inset 0px -4px #0002;
  font: inherit;

  &:active {
    box-shadow: none;
    height: 36px;
    transform: translateY(2px);
  }
`

const LogoText = styled.div`
  font-weight: 600;
  align-self: center;
  font-size: 24px;

  @media (max-width: 430px) {
    display: none;
  }
`

const LogoImg = styled.img`
  max-height: 60px;
  align-self: center;
  padding-right: 18px;

  @media (max-width: 430px) {
    max-height: 50px;
  }
`

const LogoWrapper = styled(NavLink)`
  display: inline-flex;
  color: inherit;
  text-decoration: none;
`

const Content = styled.div`
  padding-top: 90px;

  @media (max-width: 430px) {
    padding-top: 60px;
  }
`

const Logo = () => {
  return (
    <LogoWrapper to="/">
      <LogoImg src='./logo-60.png'/>
      <LogoText>FantomStains</LogoText>
    </LogoWrapper>
  )
}

const Navbar: React.FC = ({children}) => {
  const { activate, account, connector, error } = useWeb3React();
  const injected = useInjected();

  const changeNetwork = useChangeNetwork();

  const [activatingConnector, setActivatingConnector] = React.useState<
    InjectedConnector | null | undefined
  >();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const handleConnect = React.useCallback(() => {
    console.log(account, injected)
    if (injected && !account) {
      setActivatingConnector(injected);
      activate(injected);
    }
  }, [activate, injected, account]);

  React.useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      changeNetwork();
    }
  }, [error, changeNetwork]);

  return (
    <>
      <Bar>
        <Logo/>
        <Links>
          <StyledLink activeClassName='is-active' to="/mint">Mint</StyledLink>
          <ConnectButton onClick={handleConnect}>
            {account
              ? `${account.substring(0, 6)}...`
              : "Connect"}
          </ConnectButton>
        </Links>
      </Bar>
      <Content>
        {children}
      </Content>
      <Footer/>
    </>
  )
}

export default Navbar;
