import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { getInjected, InjectedContext } from "utils/injectedContext";
import React from "react";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 1000;
  return library;
}

const Providers: React.FC = ({children}) => {
  const [injected] = React.useState(() => getInjected());
  
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <InjectedContext.Provider value={injected}>
        {children}
      </InjectedContext.Provider>
    </Web3ReactProvider>
  )
}

export default Providers