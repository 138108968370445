import * as React from "react";
import { useInjected } from "../utils/injectedContext";
import { useWeb3React as _useWeb3React } from "@web3-react/core";
import { useChangeNetwork } from "./useChangeNetwork";
import { UnsupportedChainIdError } from "@web3-react/core";

export function useWeb3React() {
  return _useWeb3React()
}

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const injected = useInjected();
  const changeNetwork = useChangeNetwork();

  const [tried, setTried] = React.useState(false);

  React.useEffect(() => {
    if (injected) {
      injected.isAuthorized().then((isAuthorized: boolean) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch((e) => {
            if (e instanceof UnsupportedChainIdError) {
              changeNetwork().catch(() => setTried(true));
            } else {
              setTried(true);
            }
          });
        } else {
          setTried(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  React.useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();
  const injected = useInjected();

  React.useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress && injected) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate, injected]);
}