import PageLoader from './components/PageLoader';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import { lazy } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import GlobalStyle from './components/GlobalStyle';
import Navbar from './components/Navbar';
import history from './routerHistory'

const Home = lazy(() => import('./views/Home'))
const Mint = lazy(() => import('./views/Mint'))

function App() {
  return (
    <Router history={history}>
      <GlobalStyle />
      <Navbar>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Redirect from='/' to='/home' exact/>
            <Route path="/home" exact>
              <Home />
            </Route>
            <Route path="/mint" exact>
              <Mint />
            </Route>
          </Switch>
        </SuspenseWithChunkError>
      </Navbar>
    </Router>
  );
}

export default App
