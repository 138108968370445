import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 60px;

  @media (max-width: 430px) {
    flex-direction: column;
    padding: 0 40px 20px;
  }
`

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &.is-active {
    font-weight: 600;
  }

  > img {
    width: 38px;
    padding: 4px 8px 4px 0;
  }
`

const StyledA = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  > img {
    width: 38px;
    padding: 4px 8px 4px 0;
  }
`

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 10px 10px;
  font-size: 14pt;
`

const Footer: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Navigation>
        <h3>Navigation</h3>
        <StyledLink to='/home' activeClassName='is-active'>Home</StyledLink>
        <StyledLink to='/mint' activeClassName='is-active'>Mint</StyledLink>
      </Navigation>
      <Navigation>
        <h3>Social Media</h3>
        <StyledA href='https://discord.gg/BzKZPtJf45'><img src="./discord.svg" alt=""/>Discord</StyledA>
        <StyledA href='https://twitter.com/fantomstain' target="_blank"><img src="./twitter.svg" alt=""/>Twitter</StyledA>
      </Navigation>
    </Wrapper>
  );
};

export default Footer;
